.growlogs-content {
  padding: 100px 0 60px 0;
  // max-width: 1366px;
  margin: auto;
  background-color: $bg-color;
  min-height: 100vh;

  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: normal;
    color: $primary-color;

    h4 {
      color: $primary-color;
      font-weight: normal;
    }

    &-clear {
      font-size: .9rem;
      margin: 0 10px;
      color: #FFFFFF;
      cursor: pointer;

      &-icon {
        font-size: .9rem;
      }
    }
  }

  .search {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    &__input {
      padding: 8px 150px 8px 20px;
      background: $bg-color;
      border-radius: 3px;
      border: 1px solid #BBBBBB;
      color: #FFFFFF;
      outline: none;
      
      &::placeholder {
        color: #BBBBBB;
      }
    }


    &__icon {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 1.3rem;
      color: #bbbbbb;
    }
  }

  .filter-button {
    margin-left: 20px;
  }

  .filter-checkbox-icon {
    color: #FFFFFF;
  }

  @media screen and (max-width: $breakpoint-md ) {
    padding: 100px 10px;

    .growlogs-content-title {
      margin: 25px 0;
    }
    .search-wrapper{
      width: 100%;
      .search {
        width: 100%;
  
        &__input {
          width: 100%;
          padding: 8px 20px;
        }
      }
    }

    .filter-button {
      margin: 0;
    }
  }
}

.search-modal {
  position: absolute;
  background-color: rgba($color: $bg-color, $alpha: 0.8);
  width: 100%;
  height: 100%;
  z-index: 999999;
  padding: 50px 150px;

  &__container {
    position: relative;

    &__input {
      width: 100%;
      font-size: 2rem;
      padding: 15px 30px;
      background: #FFFFFF;
      border-radius: 5px;
      font-size: 1.8rem;
      border: 1px solid #bbbbbb;
    }

    &__icon {
      position: absolute;
      font-size: 1.5rem;
      top: 23px;
      right: 23px;
    }
  }

  &__options {
    background: #FFFFFF;
    padding: 10px;
    margin: 0 5px;
    border-radius: 0 0 5px 5px;
    border: 1px solid #bbbbbb;

    &__label {
      font-size: .9rem;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: $breakpoint-md ) {
   padding: 20px;
  }
}
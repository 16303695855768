.modal-photos {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  z-index: 99999 !important;
  color: $on-light-high-color;  

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #FFFFFF;
    cursor: pointer;
    z-index: 1;
  }
  
  &__container {
    position: relative;
    display: flex;
    align-items: center;
    height: 90%;
    max-width: 80%;
    min-width: 70%;
    background-color: $bg-color;

    &__carousel {
      position: relative;
      width: 70% !important;

      img {
        max-height: 90vh !important;
        width: auto;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #F2F2F2;
    padding: 20px 30px 30px 20px;
    height: 100%;
    width: 35%;
    font-size: .9rem;
    
    &__no-margin {
      margin: 0;
    }

    &__title {
      margin: 20px 0 30px 0;
      font-weight: normal;

      i::before {
        margin: 0 10px 0 0;
      }
    }

    &__label {
      font-weight: bolder;
      margin-bottom: 8px;
    }

    &__date {
      margin-bottom: 5px;
      font-size: .8rem;
    }
  }

  @media screen and (max-width: 960px) {
    .css-buu32a {
      opacity: 1 !important;
    }

    &__container {
      max-width: 100%;
      width: 100%;
      height: 100%;
      flex-direction: column;

      &__carousel {
        width: 100% !important;
        height: 80vh;

        img {
          max-height: 85vh !important;
        }

        .react-images__view-wrapper {
          display: flex !important;
          align-items: center;
          justify-content: center;
          height: 80vh;
        }
      }
    }

    &__info {
      position: absolute;
      bottom: 0;
      height: auto;
      width: 100%;

      &__title {
        margin: 0;
        font-size: 1.5rem;
      }
    }
  }
}
.icon-rounded {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 20px; 
  border-radius: 100px;
  background-color: $primary-color;
  margin-right: 10px;

  i {
    color: white;
  }
};
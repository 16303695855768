.footer {
  bottom: 0;
  width: 100%;
  padding: 40px 60px;
  font-size: 14px;
  background-color: $bg-color;
  color: rgba(255,255,255,.9);

  &-section {
    margin-bottom: 40px;

    a {
      margin: 10px 0;
      color: rgba(255,255,255,.9);
      font-weight: 400;
      text-decoration: underline;
    }
  }

  &-logo{
    width: 130px;
    margin-right: 20px
  }

  &-img {
    max-width: 140px;
    margin-bottom: 10px;

    &-instagram {
      max-width: 40px;
      max-height: 40px;
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    padding: 20px;
  }
}

.profile-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  flex-flow: wrap;
  position: relative;
  max-height: 350px;
  overflow: hidden;
  box-shadow: 0px 10px 15px -2px rgba(181,181,181,1);

  &-image {
    filter: brightness(.8);
    width: 100%;
  }

  &-title {
    position: absolute;
    color: #ffffff;
    max-width: $breakpoint-lg;
    padding: 10px 40px;

    &-avatar {
      height: 80px !important;
      width: 80px !important;
      margin: 10px;
      border: 2px solid #ffffff;
    }

    h1 {
      font-size: 2.5rem;
      margin: 10px;
      overflow: hidden;
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    max-height: 200px;

    h1{
      font-size: 1.5rem;
    }

    &-title {
      padding: 0 5px;
    }


    &-title{
      &-avatar {
        height: 60px !important;
        width: 60px !important;
      }
    }
  }

}
.growlog-gallery {
    position: relative;
    margin-bottom: 50px;
    margin-top: 20px;
    max-height: 350px;
    cursor: pointer;
    
    &__images-count {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 30px;
        background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.76234243697479) 38%, rgba(0,0,0,0.6530987394957983) 54%, rgba(0,0,0,0.5158438375350141) 67%, rgba(0,0,0,0.32816876750700286) 81%, rgba(0,0,0,0) 100%);
        color: #FFFFFF;
        z-index: 2;
    }
}
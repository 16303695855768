.dropdown {
  position: relative;
  margin: 15px 0;
  width: 100%;

  &__button {
    width: 100%;
    padding: 10px 35px 10px 20px;
    background: $bg-color;
    border: 1px solid #bbbbbb;
    border-radius: 5px;
    font-size: .9rem;
    color: #FFFFFF;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;

    &__icon {
      position: absolute;
      top: 10px;
      right: 10px;
      color: #FFFFFF;
    }

    &__no-border {
      border: none;
    }
  }

  &__options_group {
    min-width: 200px;
    width: 100%;
    position: absolute;
    padding: 10px;
    top: 130%;
    transform: translateX(-50%);
    left: 50%;
    background: $bg-color;
    border: 2px solid #212121;
    z-index: 9999;

    &__item {
      padding: 10px;
      width: 100%;
      color: #FFFFFF;
      cursor: pointer;

      &:hover {
        background-color: #212121;
      }
    }

    &::before {
      content: "";
      border-bottom: 9px solid #212121;
      border-left: 9px solid rgba(0, 0, 0, 0);
      border-right: 9px solid rgba(0, 0, 0, 0);
      box-shadow: 0px -2px 0px -2px rgba(181,181,181,1);
      display: inline-block;
      transform: translateX(-50%);
      left: 50%;
      position: absolute;
      top: -8px;
    }
  }

  @media screen and (max-width: $breakpoint-md ) {
    margin: 10px 0;
  }
}
.input-container {
  position: relative;

  input {
    border: 2px solid #bbbbbb;
    padding: 10px;
    border-radius: 2px;
  }

  &-icon {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

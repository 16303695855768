main {
  padding: 0;
}

body {
  margin: 0;
  padding: 0 !important;
  box-sizing: border-box;
  font-size: 14px;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bg-color;
  color: $on-surface-high-color;
}

ul, ol {
  padding-left: 0;
  margin-bottom: 0;
}

a {
  transition: all 0.3s;
  color: $primary-color;
  &:hover {
    text-decoration: none;
  }
}

p {
  margin: 0;
}

img {
  width: 100%;
  max-width: initial;
}

.fullWidth {
  width: 100%;
}

.container {
  //width: 80% !important;
  max-width: 1080px;
  margin: auto !important;

  // @media screen and (max-width: $breakpoint-sm) {
  //   padding: 0 20px !important;
  // }
};

.margin {
  &-left {
    &-10 {
      margin-left: 10px;
    }

    &-15 {
      margin-left: 15px;
    }

    &-20 {
      margin-left: 20px;
    }
  }

  &-right {
    &-10 {
      margin-right: 10px;
    }

    &-15 {
      margin-right: 15px;
    }

    &-20 {
      margin-right: 20px;
    }
  }

  &-bottom {
    &-10{
      margin-bottom: 10px;
    }

    &-15 {
      margin-bottom: 15px;
    }

    &-20 {
      margin-bottom: 20px;
    }
  }
}

.slides {
  position: relative;
  margin: auto;

  .slick-prev, .slick-next {
    display: none !important;
    position: absolute;
    top: 50%;
    &::before {
      color: $primary-color !important;
    }
  }
  .slick-prev {
    left: -15px !important;
    z-index: 1;
  }
  .slick-next {
    right: -15px !important;
    z-index: 1;
  }

  &:hover {
    .slick-prev, .slick-next{
      display: block !important;
    }
  }
}

.comments-drawer{
  width: 50%;
  @media screen and (max-width: $breakpoint-md) {
    width: 70%;
  }
  @media screen and (max-width: $breakpoint-sm) {
    width: 90%;
  }
  .comments-header{
    padding: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;
    svg{
      color: rgba(0, 0, 0, 0.54);
      margin-right:8px;
    }
  }
}

.load__icon {
  animation: linear load 2s infinite;
  width: 32px;
  height: 32px;
}

.load__icon-wrap {
  margin: auto;
}

.load {
  height: calc(100vh - 16px);
  width: 100%;
  display: flex;
  align-items: center;

  &__mobile {
    height: 150px;
  }
}

@keyframes load {
  from {
    transform: rotate(0deg) scale(2);
  }
  to {
    transform: rotate(360deg) scale(2);
  }
}

.hidden{
  display: none;
}

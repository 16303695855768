.gallery-modal {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 9999;
  color: $on-light-high-color;
  background-color: rgba($color: $bg-color, $alpha: .7);

  &__content {
    margin-top: 70px;
    position: relative;
    background-color: #FFFFFF;
    padding: 0 15px;
    width: 80%;
    height: 80%;
    max-width: 80%;
    // max-height: 600px;
    border-radius: 5px;

    &__grid {
      position: relative;
      max-height: 80%;
      overflow-y: scroll;
      padding-bottom: 150px;

      img {
        cursor: pointer;
      }
    }
  }

  &__header {
    display: flex;
    background-color: #FFFFFF;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    margin: 30px 0;

    &__close-icon {
        cursor: pointer;
    }
  }

  @media screen and (max-width: 600px) {
    align-items: flex-start;
    padding: 16px;

    &__content {
      margin-top: auto;
      width: 100%;
      height: 100%;
      max-height: initial;
      overflow: hidden;

      &__grid {
        height: initial;
      }
    }
  }
}
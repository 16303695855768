.subscription {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: $primary-color;
  padding: 5px 15px;
  color: #ffffff;
  font-weight: bold;

  &-input-container {
    display: flex;

    input {
      border-radius: 100px;
      padding: 8px 10px;
      border: none;
      margin-left: 15px;
      font-size: .9rem;
    
      &::placeholder {
        color: #bbbbbb;
        
      }
    }

    &-button {
      border-radius: 100px;
      padding: 8px 15px;
      background-color: green;
      border: none;
      color: #ffffff;
      margin-left: -30px;
      font-size: .9rem;
    }
  }

}
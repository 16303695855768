.header {
  position: absolute;
  width: 100%;
  padding: 15px 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
  color: #ffffff;
  z-index: 100;

  a {
    color: rgba(255,255,255,0.6);
    text-decoration: none;
    padding-right: 22px;
    font-weight: 600;
  }

  .header-logo {
    width: 110px;
  }

  .menu_icon {
    font-size: 32px;
    color: #4caf50;
  };
  .wrapper{
  @media screen and (max-width: $breakpoint-md) {
      padding-bottom: 24px;
    }
  }
  .dropdown_menu{
    position: absolute;
    z-index: 9999;
    left: 0;
    width: 100%;
    padding: 5%;
    border-top: 3px solid #4caf50;
    background-color: $bg-color;
    -webkit-box-shadow: 0 2px 5px rgba(0,0,0,.1);
    -moz-box-shadow: 0 2px 5px rgba(0,0,0,.1);
    box-shadow: 0 2px 5px rgba(0,0,0,.1);
    -webkit-transition: .2s ease-in-out;
    transition: .2s ease-in-out;
    transform-origin: center-top;
    transform:scaleY(0);
    opacity: 0;
    transform-origin: top;
    &.open{
      transform: scaleY(1);
      opacity: 2;
    }
    a {
      border-bottom: 1px solid rgba(255,255,255,.3);
      color: rgba(255,255,255,.9);
      width: 100%;
      margin: 0;
    }
  }
}

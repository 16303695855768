&__card {
  display: flex;
  width: 100%;
  max-width: $breakpoint-lg;
  margin-top: 100px;
  color: $on-surface-high-color;

  &__image {
    background-size: cover;
    width: 70%;
  }

  &__content {
    background: $surface-bg;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 50px;
    width: 100%;

    h5 {
      font-weight: normal;
    }

    &__logo {
      width: 90px;
      height: 110px;
    }

    &__google-sign-in {
      text-align: center;
      margin-top: 50px;

      &__icon {
        margin: 10px;
        width: 30px;
        cursor: pointer;
      }
    }


    &__form {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 20px;
      
      &__input {
        margin: 10px 0;
      }
    }

  }

}

&__create-account {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 80px 0;

  h5 {
    width: max-content;
    font-weight: normal;
    border-bottom: 1px solid;
  }
}

@media screen and (max-width: $breakpoint-sm) {
  &__card {
    width: max-content;

    &__content {
      padding: 20px;
    }
  }
}
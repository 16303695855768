@font-face {
  font-family: 'jane';
  src: url('../font/jane.eot?41830659');
  src: url('../font/jane.eot?41830659#iefix') format('embedded-opentype'),
       url('../font/jane.woff2?41830659') format('woff2'),
       url('../font/jane.woff?41830659') format('woff'),
       url('../font/jane.ttf?41830659') format('truetype'),
       url('../font/jane.svg?41830659#jane') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'jane';
    src: url('../font/jane.svg?41830659#jane') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "jane";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-action:before { content: '\e800'; } /* '' */
.icon-add:before { content: '\e801'; } /* '' */
.icon-dashboard:before { content: '\e802'; } /* '' */
.icon-dead:before { content: '\e803'; } /* '' */
.icon-death:before { content: '\e804'; } /* '' */
.icon-done:before { content: '\e805'; } /* '' */
.icon-environment:before { content: '\e806'; } /* '' */
.icon-flowering:before { content: '\e807'; } /* '' */
.icon-flush:before { content: '\e808'; } /* '' */
.icon-harvest:before { content: '\e809'; } /* '' */
.icon-height:before { content: '\e80a'; } /* '' */
.icon-home:before { content: '\e80b'; } /* '' */
.icon-indoor:before { content: '\e80c'; } /* '' */
.icon-log:before { content: '\e80d'; } /* '' */
.icon-new-reminder:before { content: '\e80e'; } /* '' */
.icon-notification:before { content: '\e80f'; } /* '' */
.icon-nutrient:before { content: '\e810'; } /* '' */
.icon-outdoor:before { content: '\e811'; } /* '' */
.icon-ph:before { content: '\e812'; } /* '' */
.icon-photo:before { content: '\e813'; } /* '' */
.icon-postpone:before { content: '\e814'; } /* '' */
.icon-ppm:before { content: '\e815'; } /* '' */
.icon-repelent:before { content: '\e816'; } /* '' */
.icon-germination:before { content: '\e817'; } /* '' */
.icon-strain:before { content: '\e818'; } /* '' */
.icon-temperature:before { content: '\e819'; } /* '' */
.icon-time:before { content: '\e81a'; } /* '' */
.icon-transplant:before { content: '\e81b'; } /* '' */
.icon-trees:before { content: '\e81c'; } /* '' */
.icon-trim:before { content: '\e81d'; } /* '' */
.icon-vegetative:before { content: '\e81e'; } /* '' */
.icon-watering:before { content: '\e81f'; } /* '' */
.icon-width:before { content: '\e820'; } /* '' */
.icon-seedling:before { content: '\e821'; } /* '' */
.icon-growlog:before { content: '\e822'; } /* '' */
.icon-medium-solid:before { content: '\e823'; } /* '' */
.icon-medium:before { content: '\e824'; } /* '' */
.icon-age:before { content: '\e825'; } /* '' */
.icon-android-alert:before { content: '\e826'; } /* '' */
.icon-application_method:before { content: '\e827'; } /* '' */
.icon-archive:before { content: '\e828'; } /* '' */
.icon-arrow-expand:before { content: '\e829'; } /* '' */
.icon-arrow-shrink:before { content: '\e82a'; } /* '' */
.icon-bookmark:before { content: '\e82b'; } /* '' */
.icon-calendar:before { content: '\e82d'; } /* '' */
.icon-chart:before { content: '\e82e'; } /* '' */
.icon-co2:before { content: '\e82f'; } /* '' */
.icon-cog:before { content: '\e830'; } /* '' */
.icon-comments:before { content: '\e831'; } /* '' */
.icon-contact:before { content: '\e832'; } /* '' */
.icon-create:before { content: '\e833'; } /* '' */
.icon-curing:before { content: '\e834'; } /* '' */
.icon-drying:before { content: '\e835'; } /* '' */
.icon-earth:before { content: '\e836'; } /* '' */
.icon-ec:before { content: '\e837'; } /* '' */
.icon-explore:before { content: '\e839'; } /* '' */
.icon-filter:before { content: '\e83a'; } /* '' */
.icon-flower-log:before { content: '\e83b'; } /* '' */
.icon-environment_temp:before { content: '\e83c'; } /* '' */
.icon-calculator:before { content: '\e83d'; } /* '' */
.icon-growers-circle:before { content: '\e83e'; } /* '' */
.icon-humidity:before { content: '\e83f'; } /* '' */
.icon-images:before { content: '\e840'; } /* '' */
.icon-information-circled:before { content: '\e841'; } /* '' */
.icon-light_hanging_distance:before { content: '\e842'; } /* '' */
.icon-log-env:before { content: '\e843'; } /* '' */
.icon-log-tree:before { content: '\e844'; } /* '' */
.icon-more:before { content: '\e845'; } /* '' */
.icon-nutrient-liquid:before { content: '\e846'; } /* '' */
.icon-nutrient-solid:before { content: '\e847'; } /* '' */
.icon-nutrient-spray:before { content: '\e848'; } /* '' */
.icon-outside_temp:before { content: '\e849'; } /* '' */
.icon-person:before { content: '\e84a'; } /* '' */
.icon-pin:before { content: '\e84b'; } /* '' */
.icon-precipitations:before { content: '\e84c'; } /* '' */
.icon-rooting:before { content: '\e84d'; } /* '' */
.icon-toolbox:before { content: '\e84e'; } /* '' */
.icon-training:before { content: '\e84f'; } /* '' */
.icon-water_temperature:before { content: '\e850'; } /* '' */
.icon-weight:before { content: '\e851'; } /* '' */
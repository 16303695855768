.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  overflow: auto;

  .query{
    width: 100%;
    margin-top: $gutter *2;
    @media screen and (max-width: $breakpoint-sm) {
      margin-top: 0;
    }
  }

  @import '../shared/users.scss';

  @media screen and (max-width: $breakpoint-sm) {
    &__card {
      width: 90%;
      flex-direction: column;
      &__image {
        height: 20vh;
        width: 100%;    
      }
      &__content {
        padding: 20px;
      }
    }
  }
}


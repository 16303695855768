.download_app {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99;
  padding: 20px 20px 0 20px;
  width: 100%;
  background: linear-gradient(261.42deg, rgba(120, 0, 122, 0.3) 0%, rgba(139, 232, 253, 0.222) 99.85%), #FFFFFF;

  &__container {
    color: $primary-color;
    font-size: 1.3rem;
    max-width: 800px;

    &__logo {
      width: auto;
      height: 50px;
    }

    &__download_image {
      width: auto;
      height: 50px;
      margin: 15px 15px 0 0;
    }

    &__phone {
      margin-right: 30px;
      width: 250px;
      height: auto;
    }

    &__close {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: rgba(0, 0, 0, 0.54);
      font-size: 1rem;
      cursor: pointer;
      background: transparent;
      border: none;
    }
  }

  
  &__button {
    background-color: $primary-color;
    padding: 5px 30px;
    width: 100%;
    border-radius: 10px;
    border: none;
    margin: 15px 0;
    color: #FFFFFF;
    font-size: 1.5rem;
    text-align: center;
  }

  @media screen and (max-width: $breakpoint-md) {
    &__container {
      font-size: 1.1rem;
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    margin-bottom: 0;
    padding: 0px 10px;

    &__container {
      font-size: 1rem;
      max-width: 100%;
      margin-top: 20px;

      &__logo {
        width: auto;
        height: 50px;
      }

      &__phone {
        margin-top: 30px;
        margin-right: 10px;
        max-width: 140px;
        min-width: auto;
      }

      &__download_image {
        width: 130px;
        height: auto;
        margin: 15px 10px 0 0;
      }
    }
  }
}
.banner {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  flex-flow: wrap;
  position: relative;
  height: 40vh;
  overflow: hidden;
  box-shadow: 0px 10px 15px -2px rgba(181,181,181,1);

  &-image {
    filter: brightness(.8);
    width: 100%;
    min-height: 250px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-container-title {
    position: absolute;
    max-width: $breakpoint-lg;
    width: 80%;

    h1 {
      color: #ffffff;
      display: flex;
      align-items: center;
      font-size: 2.5rem;
      margin: 10px;
      overflow: hidden;
    }

    .views {
      font-size: 2rem;

      &-icon {
        font-size: 2.5rem;
        margin-left: 10px;
      }
    }
  }
  @media screen and (max-width: $breakpoint-md) {
    h1{
      font-size: 2rem;
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    max-height: 260px;

    &-image {
      width: 150%;
      max-width: initial;
    }

    h1{
      font-size: 1.5rem !important;
    }

    &-container-title {
      padding: 0 5px;

      .views {
        font-size: .9rem !important;

        &-icon {
          font-size: 1rem !important;
          margin-left: 5spx !important;
        }
      }
    }
  }
}

.growlog-container {
  padding-top: $gutter;
  color: $on-light-high-color;
  background-image: linear-gradient(108.9deg, #d7edef 10.4%, #cdfadc 56.2%, #eef3dc 86.1%);
}

.timeline__image-grid {
  overflow: hidden;
  width: 100%;

  &__item{
    overflow: hidden;
    cursor: pointer;

    &__img {
      min-height: 200px;
      min-width: 244px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    &__item {
      min-width: 120px;
    }
  }
}

.one-image {
  @media screen and (max-width: $breakpoint-sm) {
    min-width: 350px !important;
  }
}

.environment-content {
  padding-right: 60px;

  @media screen and (max-width: $breakpoint-sm) {
    padding-right: 0;
  }
}

.stage-content {
  .stage-icon {
    .timeline-line {
      margin-left: 23px;
    }
  }
  .stage-details {
    margin-left: 16px;
  }
  .stage-name {
    text-transform: capitalize;
  }
}

.timeline-circle {
  width: 25px;
  height: 25px;
  border-radius: 100px;
  background-color: $primary-color;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14);
}

.timeline-line {
  height: 25px;
  width: 4px;
  background-color: $primary-color;

  &-horizontal {
    height: 4px;
    background-color: $primary-color;
  }
}

.icon {
  font-size: 20px;
  margin-right: 10px;
}

.date-container {
  position: relative;

  .date-right {
    display: flex;
    position: absolute;
    align-items: center;
    left: 150%;
    top: 0;
    height: 100%;
    width: max-content;
  }

  .date-left {
    display: flex;
    position: absolute;
    align-items: center;
    right: 150%;
    top: 0;
    height: 100%;
    width: max-content;
  }
}
.fab-comments{
  position: fixed;
  background-color: $primary-color;
  right: 32px;
  bottom: 32px;
  z-index: 999;
  svg{
    margin-right: 8px;
  }
}

.card {
  display: flex;
  justify-content: flex-end;
  color: #ffffff;
  height: 400px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-sizing: border-box;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 3px 3px rgba(0, 0, 0, 0.14);
  background-color: #212121;
  border: none !important;
  cursor: pointer;
  
  h4{
    color: #ffffff;
  }

  &-details {
    border-radius: 0 0 3px 3px;
    font-size: .9rem;
    padding: 8px;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5326505602240896) 28%, rgba(0,0,0,0.700717787114846) 60%, rgba(0,0,0,0.8519782913165266) 80%, rgba(0,0,0,1) 100%);
    
    &-growlog-data {
      padding: 15px 0;

      p {
        text-align: center;
      }
    }
    
    &-social-icons {
      display: flex;
      align-items: center;
      padding: 5px;
    }
  }

  &-label {
    font-size: .8rem;
    color: #828282;
    padding: 5px 0;
  }

  @media screen and (max-width: $breakpoint-sm) {
    justify-content: space-between;
    max-height: 150px;
    
    &-details {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 0px 8px;
      border-radius: 0;

      &-name {
        width: 100%;
        padding: 5px 8px;
        font-size: .8rem;
        background: linear-gradient(0, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5326505602240896) 28%, rgba(0,0,0,0.700717787114846) 60%, rgba(0,0,0,0.8519782913165266) 80%, rgba(0,0,0,1) 100%);


        h4 {
          font-size: 1.2rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &-social-icons {
        display: flex;
        justify-content: flex-end;
        padding: 10px 0;
        font-size: .8rem;
      }
    }
    
    &-image{
      max-height: 180px;
    }

    &-label {
      color: #ffffff;
    }
  }
}
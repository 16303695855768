@import 'generic/normalize.scss';
@import 'generic/box-sizing.scss';
@import './base/variables.scss';
@import './base/scaffolding';

#my-react-page {
    //base...
    @import './base/animation.css';
    @import './base//jane-codes.css';
    @import './base/jane-embedded.css';
    @import './base/jane-ie7-codes.css';
    @import './base/jane-ie7.css';
    @import './base/jane.css';

    //containers...
    @import './containers/growlog.scss';
    @import './containers/growlogs.scss';
    @import './containers/profile.scss';
    @import './containers/login.scss';
    @import './containers/onboarding.scss';
    @import './containers/register.scss';

    // components
    @import './component/layout.scss';
    @import './component/icon.scss';
    @import './component/growlogCard.scss';
    @import './component/subscription.scss';
    @import './component/input.scss';
    @import './component/listItem.scss';
    @import './component/dropdown.scss';
    @import './component/button.scss';
    @import './component/header.scss';
    @import './component/footer.scss';
    @import './component/load.scss';
    @import './component//downloadApp.scss';
    @import './component/gallery.scss';
}

@import './component/modalPhotos.scss';
@import './component/galleryModal.scss';

.onboarding {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  overflow: auto;

  @import '../shared/users.scss';

  &__card{
    text-align: center;
    margin:$gutter;
    min-height: 80vh;
    width: auto;
    max-width: 1024px;
    width: 80%;
    @media screen and (max-width: $breakpoint-sm) {
      flex-direction: column;
      margin: 16px;
      font-size: .8em;
      min-width: 90%;
    }
    &__sidepanel{
      position: relative;
      flex-grow: 1;
      width: 100%;
      @media screen and (max-width: $breakpoint-sm) {
        height: 25vh;
      }
      .credits{
        position: absolute;
        bottom: $gutter/2;
        left: $gutter/2;
        background-color: rgba(0,0,0,0.6);
        padding: $gutter/2;
        border-radius: 16px;
        @media screen and (max-width: $breakpoint-sm) {
          font-size: .8em;
        }
        span{
          padding-right: 8px;
        }
      }
    }
    &__image{
      width: 100%;
      height: 100%;
    }
    &__content{
      flex-grow: 2;
      padding: $gutter;
      &__form{
        display: block;
        margin: 0;
        max-width: 400px;
      }
      button{
        padding: $gutter/2 $gutter;
        width:100%;
      }
      #create-profile{
        .profile{
          margin: auto;
          width: 80px;
          height: 80px;
          margin-bottom: $gutter;
          background-color: rgba(70,150,0,.4);
          svg{
            font-size: 2.5rem;
            color: $primary-color;
          }
          img{
            border-radius: 100%;
            position: absolute;
          }
        }
      }
      
    }
  }
  
  h1,h4{
    color: $primary-color;
  }
  h4,h5{
    padding-bottom: 32px;
    @media screen and (max-width: $breakpoint-sm) {
      font-size: 1.5em;
      padding-bottom: 16px;
    }
  }
  button{
    margin-top: $gutter;
    background-color:$primary-color; 
  }
}
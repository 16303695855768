.list {
  &-title {
    color: $on-light-low-color;
    letter-spacing: 1.5px;
    font-size: .9rem;
    margin: 20px 0;
  }

  &-label {
    color: $on-light-low-color;
  font-size: .9rem;
  }

  &-border-bottom {
    padding-bottom: 10px;
    width: 100%;
    border-bottom: 1px solid #d6cfcf;
  }
}
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');

$app-gradient-color: linear-gradient(108.9deg, #d7edef 10.4%, #cdfadc 56.2%, #eef3dc 86.1%);
$breakpoint-sm: 600px;
$breakpoint-md: 960px;
$breakpoint-lg: 1080px;
$breakpoint-xl: 1920px;

$bg-color:#121212;
$surface-bg: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #121212;
$primary-color: #4CAF50;
$secondary-color: #FFEB3B;
$on-primary-high-color: #FFFFFF;
$on-primary-low-color: rgba(255, 255, 255, 0.74);
$on-surface-high-color: rgba(255, 255, 255, 0.87);
$on-surface-low-color: rgba(255, 255, 255, 0.6);
$on-light-high-color: rgba(0,0,0, 0.87);
$on-light-low-color: rgba(0, 0,0, 0.6);

$gutter: 16px;
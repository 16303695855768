.load {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  position: fixed;
  background: transparent;
  z-index: 1000;

  & + div {
    height: 100vh;
    overflow: hidden;
  }

  &.loaded {
    animation: ease loaded 0.5s;
  }
}

.load__icon {
  animation: linear load 2s infinite;
  margin: auto;
  width: 32px;
  height: 32px;
}

.load__icon-wrap {
  margin: auto;
}

@keyframes load {
  from {
    transform: rotate(0deg) scale(2);
  }
  to {
    transform: rotate(360deg) scale(2);
  }
}

@keyframes loaded {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.loader-small{
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-left: 4px solid;
  animation: load 1s infinite linear;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
